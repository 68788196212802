export default {
    welcome: `
      <h1>
        Bienvenue sur le site de l’assurance de responsabilité
        professionnelle pour les associations en Médecine Alternative.
      </h1>`,
    slogan: `<h2>Un programme d'assurance spécifiquement conçu pour vous</h2>`,
    introduction: `
        <p>Bonjour !</p>
        <p>
            Vous êtes sur le nouveau site de l’assurance de responsabilité
            professionnelle, destiné spécifiquement aux associations en
            Médecine Alternative. Ce site transactionnel a pour but
            d’améliorer et d’accélérer le service d’ESSOR Assurances en vous
            permettant de vous inscrire directement et de vous offrir un
            produit adapté à votre profession.
        </p>
        <p>N’hésitez pas à nous faire part de vos commentaires.</p>`,
    hook_text:`<h2>Un programme d'assurance complet et unique</h2>`,
    description: `
       <p>
          ESSOR Assurances vous propose un programme d’assurance complet et
          unique, spécialement adapté aux membres des associations en
          Médecine Alternative.
        </p>
        <p>
          Soucieux de vous en offrir toujours plus, nous vous offrons des
          garanties plus étendues incluant les volets de responsabilité
          civile générale et de responsabilité professionnelle.
        </p>
        <p>
          Cette assurance vous assure également une protection pour les
          biens de votre entreprise ainsi qu’une couverture contre le vol et
          le détournement.
        </p>`,
    please_select: `Veuillez sélectionner votre association afin d’adhérer à votre assurance professionnelle en ligne.`
}
