import React from 'react'

import LogoEssor from '../../assets/logo_fr.png'
import {FormattedHTMLMessage, useIntl} from "react-intl";

function HelpCard() {
	const intl = useIntl();
	return (
		<div className="container-fluid">
			<div className="row text-center">
				<div className="col">
					<FormattedHTMLMessage id="general.helpCard" />
					<div className="text-center spacer-top-md">
						<ul className="list-inline">
							<li><img src={LogoEssor} alt={intl.formatMessage({id:'general.company_name'})} title="{intl.formatMessage({id:'general.company_name'})}" /></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HelpCard
