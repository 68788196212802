import { useState, useEffect, useCallback } from 'react';
import { useStateValue } from '../states';
import Axios from 'axios';

const useWithClient = (clientId, groupId, signature, requestParams, setToGlobalState = true) => {
  const [state, dispatch] = useStateValue()
  const [hasTried, setHasTried] = useState(false);
  const [loading, setLoading] = useState(false);
  const [theReturn, setTheReturn] = useState(false);

  useEffect(() => {
    if (theReturn && setToGlobalState) {
      dispatch({
        type: 'setLocale',
        locale: theReturn.lang,
      })

      dispatch({
        type: 'setClient',
        client: theReturn,
      })
    }
  }, [theReturn, setToGlobalState, dispatch])

  const get = useCallback(async () => {
    setLoading(true)
    const response = await (async () => {
      try {
        return await Axios.post(
          `${state.settings.apiBaseUrl}/client/${clientId}/basic/${groupId}?signature=${signature}`, requestParams
        )
      } catch (error) {
        console.error(error)
      }
    })()
    setLoading(false)
    return response?.data || false
  }, [state.settings.apiBaseUrl, setLoading, clientId, groupId, signature, requestParams])

  useEffect(() => {
    if (!loading && !hasTried) {
      ; (async () => {
        setHasTried(true)
        setTheReturn(await get() || false)
      })()
    }
  }, [loading, hasTried, get, setTheReturn]);

  return [theReturn, loading, hasTried];
}

export default useWithClient