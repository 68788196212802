import Axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useStateValue } from '../states';
import formatSubmissionToFormData from './formatSubmissionToFormData';

const useWithRenewal = (clientId, groupId, signature, setToGlobalState = true) => {
  const [state, dispatch] = useStateValue()
  const [hasTried, setHasTried] = useState(false);
  const [loading, setLoading] = useState(false);
  const [theReturn, setTheReturn] = useState(false);

  useEffect(() => {
    if (theReturn && setToGlobalState) {
      dispatch({
        type: 'setFormData',
        data: formatSubmissionToFormData(theReturn.data),
      })

      dispatch({
        type: 'setGroup',
        group: theReturn.group,
      })

      dispatch({
        type: 'setLocale',
        locale: theReturn.client.lang,
      })

      dispatch({
        type: 'setClient',
        client: theReturn.client,
      })

      dispatch({
        type: 'setRenewal',
        date: theReturn.renewal_at,
      })

      dispatch({
        type: 'setStep',
        step: 1,
      })
    }
  }, [theReturn, setToGlobalState, dispatch])

  const get = useCallback(async () => {
    setLoading(true)
    const response = await (async () => {
      try {
        return await Axios.post(
          `${state.settings.apiBaseUrl}/client/${clientId}/renewal/${groupId}?signature=${signature}`
        )
      } catch (error) {
        console.error(error)
      }
    })()
    setLoading(false)
    return response?.data || false
  }, [state.settings.apiBaseUrl, setLoading, clientId, groupId, signature])

  useEffect(() => {
    if (!loading && !hasTried) {
      ; (async () => {
        setHasTried(true)
        setTheReturn(await get() || false)
      })()
    }
  }, [loading, hasTried, setHasTried, setTheReturn, get]);

  return [theReturn, loading, hasTried];
}

export default useWithRenewal