import React from 'react'
import useWithDashboard from '../helpers/useWithDashboard'
import useWithRenewal from '../helpers/useWithRenewal'
import StepWrapper from './StepWrapper'
import BasePage from '../pages/BasePage'
import HelpCard from '../pages/Sections/HelpCard'
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";

const RenewalWrapper = () => {
  const [clientId, groupId] = useWithDashboard('/renewal');
  const signature = new URL(window.location.href).searchParams.get('signature') || false;
  const [renewal, renewalLoading, renewalHasTried] = useWithRenewal(clientId, groupId, signature)

  if (renewalLoading) {
    return <FormattedHTMLMessage id="general.loading" />
  }

  if (renewalHasTried && !renewal) {
    return <BasePage className="d-flex flex-column justify-content-center text-center flex-fill mw-500 mx-auto my-4">
      <section>
        <div className="jumbotron">
          <FormattedHTMLMessage id="renewal.fail" />
          <hr className="my-4" />
          <p>
            <FormattedMessage id="general.slogan" />
          </p>
        </div>
      </section>
      <HelpCard />
    </BasePage>
  }

  return <StepWrapper />
}

export default RenewalWrapper
