export default {
  intro:  'Please read the following contract and sign beneath the  box indicated to accept and continue.',
  sign_below: "Please sign in the box provided:",
  sign_confirm: "Please confirm your signature.",
  redo: "Redo",
  confirm: "Confirm",
  contract: `
    <div class="contract">
      <h4>CUSTOMER BROKER AGREEMENT</h4>
      <p>
        Subject to the following conditions, I give you the mandate to act as my broker and require your services as a professional advisor in insurance.
      </p>

      <ol>
        <li>
          <strong>MISSION:</strong> You must identify and assess my insurance needs based on the information I give you and buy insurance in accordance with my instructions regarding the amount of insurance and the nature of the coverage.
        </li>

        <li>
          <strong>RENEWAL:</strong> You must, without further instruction, renew the insurance bought through you to when it expires.
        </li>

        <li>
          <strong>INCREASE OF RISK:</strong> While the insurance is in effect and prior to any renewal of the policy, I must inform you of any change in the nature and use of the insured goods or of any change in my activities as an insured party that could significantly influence an insurer in establishing the premium, risk appraisal or the decision to accept it.
        </li>

        <li>
          <strong>FINANCIAL CONDITIONS</strong>
          <ol>
            <li>
              <strong>PAYMENT OF PREMIUMS:</strong> The premiums due for each policy, rider or renewal, including the taxes on them as well as any applicable fees, are payable on demand.
            </li>

            <li>
              <strong>DEFAULT OF PAYMENT:</strong> If I default on paying when due the amount owing for the payment of premiums, the reimbursement of advances and/or payment or other fees, you are authorized to inform the insurer that the policy, rider or certificate of renewal is not required, or when the insurance has come into effect, to ask on my behalf for the termination of the policy, rider or renewal; the request to terminate made in my name could be for one or several policies inasmuch as that is required so that the reimbursement of unearned premiums defray the total amount that may then be due. Advance notice of at least seven (7) days must be addressed to me before you ask the insurer to terminate the policy or before you advise him that the policy, rider or renewal certificate is not required. If a creditor is named beneficiary of the policy, such a notice will be sent to him within the period specified by law or in the policy.
            </li>

            <li>
              <strong>DELIVERY OF NOTICES:</strong> The advance notice required under clause 4.2 must be in writing and is assumed to have been sufficiently and validly given if it is hand-delivered. This notice may also by sent by mail or by courier service and such a notice shall be deemed to be received on the seventh (7th) working day after it was mailed or given to the courier service. The notice may be given by fax, and in that case, is considered to have been received on the date indicated in the fax confirmation page. The notice may be given by email.
            </li>
          </ol>
        </li>

        <li>
          <strong>TERMINATION OF CONTRACT:</strong> You may terminate the present contract by sending me a notice in writing containing a mandate waiver, in which case the contract is terminated at the date such a notice is received. I may also terminate the contract by sending you a notice in writing of revocation of mandate,  sending it to you by mail or by courier service, in which case the contract is terminated upon your receipt of the notice.
        </li>
      </ol>
    </div>
  `,
}
