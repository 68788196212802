export default {
  company_name: 'Essor Insurance',
  slogan: 'ESSOR Assurances, on s’en occupe depuis plus de 60 ans.',
  welcome: 'Welcome!',
  select_a_group: 'Please select a group to continue:',
  yes: 'Yes',
  no: 'No',
  other: 'Other',
  others: 'Others',
  go_to_step: "Go to step",
  previous_step: "Go to previous step",
  next_step: "Go to next step",
  begin: 'Begin',
  please_wait: 'Please wait...',
  loading: `<section>Loading in progress, please wait...</section>`,
  support: `
      <p>
        For assistance, please contact a member of our team by email at <a href="mailto:medecinealternative@essor.ca">medecinealternative@essor.ca</a> or dial 1-877 883-7767 extension 81040. Thank you.
      </p>`,
  notFound:`
      <h2>Oops!</h2>
      <p class="lead">The page you have requested does not exist.</p>`,
  helpCard:`
      <h3>Do you have insurance needs?</h3>
      <p> Our damage insurance brokers can offer you a complete range of products.</p>
      <p><strong>Automobile – Recreational vehicle– Group – Home – Business.</strong></p>
      <p>Visit <a href="https://www.essor.ca/fr"><strong>essor.ca</strong></a> to find out more.</p>`,
  excluded_activities:`
      <p><em>Please note that these activities are excluded at all times: Lecher Antennas, Ayurveda, Total Biology, 
      Biological Decoding, Biological Deprogramming, Detoxification (food), Bach Flower Elixirs, Iridology, 
      Colon Irrigation, Applied Kinesiology, Holistic Kinesiology, Magnetism, Traditional African Medicine, 
      Traditional Chinese Medicine, Nutritional Therapy, Phytotherapy, Reconnective Healing, Sympathetic Therapy, 
      Energy Technique, Sports Therapy.</em></p>`,

}
