import React from 'react';
import { IntlProvider } from 'react-intl';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import App from './App';
import locales from './i18n';
import { reducer, initialState, StateProvider, useStateValue } from './states';

const AppWrapper = () => {
  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <IntlWrapper />
    </StateProvider>
  )
};

// optional cofiguration
const alertOptions = {
  template: AlertTemplate,
  position: positions.BOTTOM_CENTER,
  timeout: 10000,
  transition: transitions.FADE,
};

const IntlWrapper = () => {
  const [state] = useStateValue();
  const { locale } = state;
  const messages = locales[locale];

  return (
    <AlertProvider {...alertOptions}>
      <IntlProvider locale={locale} messages={messages}>
        <App />
      </IntlProvider>
    </AlertProvider>
  );
};

export default AppWrapper;
