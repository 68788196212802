import classnames from 'classnames'
import React, {useState, useEffect} from 'react'
import {useLocation} from 'wouter'
import {useStateValue} from '../states'
import {FormattedHTMLMessage} from "react-intl";

const StepWrapper = () => {
    const [component, setComponent] = useState(null)
    const [state, dispatch] = useStateValue()
    const [, setLocation] = useLocation()
    const {group, step} = state

    useEffect(() => {
        if (group) {
            ;(async () => {
                try {
                    setComponent(await import(`./${group.title.toLowerCase()}`))
                } catch (error) {
                    console.error(error)
                    setComponent(null)
                    dispatch({
                        type: 'setGroup',
                        group: null,
                        step: 0,
                    })
                    setLocation('/page/default')
                }
            })()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group])

    if (!component) {
        return <FormattedHTMLMessage id="general.loading" />
    }

    const StepComponent = component[`Step${step}`]

    return (
        <section className={classnames('step-wrapper')}>
            <StepComponent/>
        </section>
    )
}

export default StepWrapper
