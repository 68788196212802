export default {
  step: 'Step',
  step1: {
    title: 'Your contact details',
    presentation:
      'All data collected on this form will be used for insurance purposes only and will be stored securely.',
  },
  step2: {
    title: 'Specialities',
  },
  step3: {
    title: 'Professional insurance',
  },
  step4: {
    title: 'Property insurance',
  },
  step5: {
    title: 'Insurance against embezzlement',
  },
  step6: {
    title: 'Past activities',
  },
  step7: {
    title: "Acceptance of the agreement",
   },
  step8: {
    title: 'Insurance premiums',
  },
  base0: {
    lead: '<b>Obtain</b> your professional liability insurance online.',
    text: 'As a member of your association, you may subscribe to professional liability insurance by completing the following form.'
  },
  base1: {
    phoneMainEmpty: 'You must first enter a main telephone number before continuing.',
    emailExists: `
      <h2>Before we continue</h2>
      <p class="lead">
          Our system indicates that you already have an account with us.
      </p>
      <p>
           As a safety measure, we must confirm you identity in order to continue.
      </p>
      <p>
          An email containing the instructions to follow has been sent to you at {debouncedEmail}.
      </p>`
  }
}