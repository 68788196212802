export default {
  your_cart: 'Your Cart',
  select: 'Select',
  remove: 'Remove',
  product: 'Product',
  price: 'Price',
  type: 'Type',
  tps: 'GST/HST',
  tvq: 'QST',
  sub_total: 'Sub-total',
  total: 'Total',
  text_taxes: 'The amount of the premium includes the company\'s taxes and fees',
  activities: 'Activities'
}
