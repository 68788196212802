export default {
    success: `
        <h2>Nous avons bien reçu votre demande</h2>
        <p class="lead">
            Votre demande afin de désactiver les prochains renouvellements a bien été reçue. Il est possible
            qu'un agent entre en contact avec vous d'ici peu.
        </p>`,
    fail: `
        <h2>Votre demande n'a pu être traitée</h2>
        <p class="lead">
            Veuillez communiquer avec notre équipe, une erreur est survenue lors de la désactivation du renouvellement
        </p>
      `,
}
