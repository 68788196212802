export default {
  liability_insurance: 'Assurance responsabilité',
  property_insurance: 'Assurance des biens',
  crime_insurance: 'Assurance contre le détournement',
  accessory_protections: 'Protections accessoires',
  public_liability: 'Limite :',
  annual_premium: 'Prime',
  insurance_amount: "Montant d'assurance",
  type: "Type",
  configure: 'Configurer',
  you_refused: 'Vous avez refusé la protection « {title} »',
  i_changed_my_mind: "J'ai changé d'idée",
  effective_date: "Date d'entrée en vigueur",
  make_payment: 'Passer au paiement',
  send_request: 'Envoyer la demande',
  representative_will_reach_u:
    'Un courtier communiquera avec vous afin de compléter votre demande.',
  liability_insurance_text: `
      <p>
        Assurance responsabilité professionnelle et civile générale et
        remboursement des frais légaux relatifs à des accusations de nature
        pénale
      </p>
      <p>
        Veuillez choisir les assurances responsabilités désirées selon vos
        spécialités:
      </p>`,
  accessory_protections_text: `
      <p>
        Nous avons une tarification avantageuse pour assurer plusieurs autres
        activités et services complémentaires offerts par les membres de
        l’association. Communiquez avec nous pour de plus amples renseignements.
      </p>`,
}
