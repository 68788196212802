import * as yup from 'yup'
import 'yup-universal-locale'

yup.addMethod(yup.string, 'postalCode', function test(message) {
  return this.test({
    name: 'postalCode',
    message,
    test: val =>
      // eslint-disable-next-line no-useless-escape
      /([ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ])\ ?([0-9][ABCEGHJKLMNPRSTVWXYZ][0-9])/.test(
        val,
      ),
  })
})
