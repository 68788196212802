export default {
  limit: 'Limite : 10 000 $ par réclamation (moyennant une surprime de 32.70$)',
  legal: `
    <section class="contract">
      <h4>À PROPOS DE LA GARANTIE</h4>
      <p>
        Cette garantie couvre le <strong>détournement</strong>, soit les pertes
        de biens, notamment l’argent et les valeurs, que l’Assuré peut subir
        directement du fait de tout acte malhonnête ayant ses employés pour
        auteurs ou complices, étant précisé que la garantie joue, par sinistre,
        à concurrence du montant stipulé.
      </p>
      <p>
        Cette garantie couvre la perte ou la <strong>détérioration</strong>
        d’argent et de valeurs du fait de leur soustraction frauduleuse,
        destruction ou disparition <strong>sur les lieux</strong> assurés ou à
        l’intérieur de locaux bancaires ou d’autres endroits de dépôt dûment
        assimilables à ces derniers.
      </p>
      <p>
        Cette garantie couvre la perte ou la <strong>détérioration</strong>
        d’argent et de valeurs survenant <strong>hors des lieux</strong> assurés
        du fait de leur soustraction frauduleuse, destructions ou disparition en
        cours de transport par un porteur ou par une entreprise de transport
        utilisant des véhicules automobiles blindés.
      </p>
      <p>
        Cette garantie couvre la
        <strong>contrefaçon de mandats ou de billets de banque</strong>, par
        l’acceptation de bonne fois, contre un service de faux billets de banque
        canadiens ou américains.
      </p>
      <p>
        Cette garantie couvre la
        <strong>contrefaçon préjudiciable aux déposants</strong>, soit les
        pertes subies par l’Assuré du fait de la contrefaçon de toute promesse
        de paiement fait par l’Assuré par des chèques ou traites au nom de
        l’Assuré.
      </p>
    </section>
  `,
}
