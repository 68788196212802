import classNames from 'classnames'
import React from 'react'
import { Route, Switch } from 'wouter'
import useLocation from 'wouter/use-location'

import Mainlogo from './assets/logo_fr.png'

import Dashboard from './clientArea/Dashboard'
import FormWrapper from './forms/FormWrapper'
import { GroupLogo } from './forms/GroupLogo'
import IdentityVerifiedWrapper from './forms/IdentityVerifiedWrapper'
import RenewalWrapper from './forms/RenewalWrapper'
import './helpers/customYupRules'
import DebugMenu from './helpers/DebugMenu'
import Page404 from './pages/Page404'
import PageWelcome from './pages/PageWelcome'
import SubmissionSuccess from './pages/SubmissionSuccess'
import TransactionError from './pages/TransactionError'
import TransactionSuccess from './pages/TransactionSuccess'
import AutoRenewDisabled from "./pages/AutoRenewDisabled";
import AutoRenewDisabledFail from "./pages/AutoRenewDisabledFail";
import LanguageSwitcher from "./components/LanguageSwitcher";

const App = () => {
  const [location] = useLocation()

  const isPage = location.includes('/page/')
  const isHome = location === '/'

  return (
    <div className="App">
      <div id="header">
        <div className="container-fluid padding-xs">
          <div className="row">
            <div className="col d-flex justify-content-center justify-content-lg-start align-items-center">
              <a href="/" className="header__logo">
                <img
                  src={Mainlogo}
                  alt="Essor Assurances"
                  title="Essor Assurances"
                />
              </a>
              <div className="header__logo">
                <GroupLogo isHome={isHome}/>
              </div>
            </div>
            <div>
              <LanguageSwitcher className='text-right' />
            </div>
          </div>
        </div>
      </div>

      <div className={classNames({container: !isHome, page: isPage})}>
       
        <Switch>
          <Route path="/" component={PageWelcome} />

          <Route path="/page/disable-auto-renew" component={AutoRenewDisabled} />
          <Route path="/page/disable-auto-renew-fail" component={AutoRenewDisabledFail} />

          <Route
            path="/page/transaction-success"
            component={TransactionSuccess}
          />
          <Route path="/page/transaction-error" component={TransactionError} />
          <Route
            path="/page/submission-success"
            component={SubmissionSuccess}
          />
          <Route path="/page/404" component={Page404} />

          <Route path="/form/:group" component={FormWrapper} />
          <Route
            path="/form/continue/client/:client/group/:group"
            component={IdentityVerifiedWrapper}
          />

          <Route
            path="/dashboard/client/:client/group/:group"
            component={Dashboard}
          />
          <Route
            path="/dashboard/client/:client/group/:group/renewal"
            component={RenewalWrapper}
          />

          <Route path="/:rest*" component={Page404} />
        </Switch>
      </div>
      <DebugMenu />
    </div>
  )
}

export default App
