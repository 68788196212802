export default {
  step: 'Étape',
  step1: {
    title: 'Vos coordonnées',
    presentation:
      'Toutes les données recueillies dans ce formulaire seront utilisées seulement pour des fins d’assurances et conservées en toute sécurité.',
  },
  step2: {
    title: 'Vos spécialités',
  },
  step3: {
    title: 'Assurances professionnelles',
  },
  step4: {
    title: 'Assurances de biens',
  },
  step5: {
    title: 'Assurances contre le détournement',
  },
  step6: {
    title: 'Activités antérieures',
  },
  step7: {
    title: "Acceptation de l'entente",
  },
  step8: {
    title: 'Primes',
  },
  base0: {
    lead: '<b>Obtenez</b> en ligne votre assurance de responsabilité professionnelle.',
    text: 'En tant que membre de votre association, vous pouvez adhérer à l\'assurance de responsabilité professionnelle en complétant le formulaire suivant.'
  },
  base1: {
    phoneMainEmpty: 'Vous devez entrer un numéro de téléphone principal d’abord.',
    emailExists: `
      <h2>Avant de continuer</h2>
      <p class="lead">
          Notre système indique que vous avez déjà un compte avec nous.
      </p>
      <p>
          Par mesure de sécurité, nous devons valider votre identité pour continuer.
      </p>
      <p>
          Un courriel contenant les indications à suivre vous a été envoyé à {debouncedEmail}.
      </p>`
  }
}