import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {FormattedHTMLMessage} from "react-intl";
import LanguageSwitcher from "../components/LanguageSwitcher";

const BasePage = ({children, className}, props) => (
    <div className={classNames('container-base', className)} {...props}>
        {children}
        <div className="container text-center p-5">
            <FormattedHTMLMessage id="general.support" />
            <LanguageSwitcher/>
        </div>
    </div>
)

BasePage.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
}

BasePage.defaultProps = {
    className: '',
}

export default BasePage
