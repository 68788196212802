import React, { useState } from 'react'
import Input from '../../Input'
import {FormattedMessage} from "react-intl";

const Contact = ({ validationGroup = '1' }) => {
  const [phoneMain, setPhoneMain] = useState('');

  return (
  <section className="contact-information">
    <div className="row">
      <div className="col-sm">
        <Input data-hj-allow fieldPath="address.street" required validationGroup={`step${validationGroup}`} />
      </div>
      <div className="col-sm">
        <Input data-hj-allow fieldPath="address.city" required validationGroup={`step${validationGroup}`} />
      </div>
      <div className="col-sm">
        <Input
          data-hj-allow
          fieldPath="address.postal_code"
          required
          validationGroup={`step${validationGroup}`}
          rules={[{ rule: 'postalCode' }]}
          applyFilterOnChange={value =>
            value
              .toString()
              .replace(/\s/g, '') // remove spaces
              .substring(0, 6) // keep 6 characters max
              .toUpperCase()
          }
        />
      </div>
    </div>
    <div className="row">
      <div className="col-sm">
        <Input data-hj-allow fieldPath="phone.main" onChange={event => setPhoneMain(event.nextState.value)} required validationGroup={`step${validationGroup}`} />
      </div>
      <div className="col-sm">
        <Input data-hj-allow fieldPath="phone.secondary" />
        {
          (!(phoneMain?.length > 0)) 
            ? <div style={{ display: 'block', marginTop: '-15px'}} className="invalid-feedback"><FormattedMessage id="steps.base1.phoneMainEmpty" /></div>
            : ''
        }
      </div>
    </div>
  </section>
)}

export default Contact
