import { useState, useEffect, useCallback } from 'react';
import { useStateValue } from '../states';
import Axios from 'axios';

const useWithGroup = (groupId, setToGlobalState = true) => {
  const [state, dispatch] = useStateValue()
  const [hasTried, setHasTried] = useState(false);
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState(false);

  useEffect(() => {
    if (group && setToGlobalState) {
      dispatch({
        type: 'setGroup',
        group: group,
      })
    }
  }, [group, setToGlobalState, dispatch])

  useEffect(() => {
    if (group) {
      (async () => {
        try {

          dispatch({
            type: 'setPriceReducer',
            priceReducer:
              (await import(`../forms/${group.title.toLowerCase()}/priceReducer`))?.default,
          })
        } catch (error) { }
      })()
    }
  }, [group, dispatch])

  const getGroup = useCallback(async () => {
    setLoading(true)
    const response = await (async () => {
      try {
        if (isNaN(groupId)) {
          return await Axios.post(
            `${state.settings.apiBaseUrl}/group/findByTitle`,
            { title: groupId }
          )
        }

        return await Axios.get(
          `${state.settings.apiBaseUrl}/group/${groupId}`,
        )
      } catch (error) {
        console.error(error)
      }
    })()
    setLoading(false)
    return response?.data || false
  }, [state.settings.apiBaseUrl, groupId, setLoading])

  useEffect(() => {
    if (!group && !loading && !hasTried) {
      ; (async () => {
        setHasTried(true)
        const leGroup = await getGroup()
        if (leGroup?.data) {
          setGroup(leGroup.data)
        }
      })()
    }
  }, [group, loading, hasTried, getGroup, setGroup]);

  return [group, loading, hasTried];
}

export default useWithGroup