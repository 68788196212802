export default {
    success: `
        <h2>Thank you for your application</h2>
        <p class="lead">
          You will receive your insurance certificate by email in the next few minutes.
        </p>`,
    fail: `
        <h2>A problem has occured with your transaction</h2>
        <p class="lead">
          A broker will contact you to complete your application.
        </p>`,
}