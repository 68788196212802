export default {
  firstname: 'Prénom',
  lastname: 'Nom de famille',
  member_number: 'Numéro du membre',
  company_name: 'Nom de votre entreprise',
  email: 'Adresse courriel',
  activities: 'Activités',
  address: {
    street: 'N° civique et rue',
    city: 'Ville',
    postal_code: 'Code postal',
  },
  phone: {
    main: 'Téléphone principal',
    secondary: 'Autre numéro de téléphone',
    fax: 'Télécopieur',
  },
  criminal_records: 'Avez-vous un casier judiciaire (dossier criminel) ?',
  criminal_records_detail: 'Veuillez préciser :',
  specialities: 'Sélectionnez vos spécialités :',
  speciality_details: 'Détaillez les autre(s) spécialité(s) :',
  recognized_activities_techniques:
    'Quelles sont vos activités et/ou techniques reconnues par votre association ?',
  unrecognized_activities:
    'Avez-vous des activités ou techniques non reconnues par votre association ?',
  unrecognized_activities_details: 'Veuillez préciser :',
  ambassador_member: 'Êtes-vous membre ambassadeur ?',
  services_outside_canada: 'Rendez-vous des services hors du Canada ?',
  annual_income:
    'Quel est votre revenu annuel relié à votre pratique professionnelle ?',
  leased_local: 'Exercez-vous vos activités dans un local lié à un bail ?',
  local_shared:
    "Est-ce qu'il y a d'autres professionnels qui partagent votre local ?",
  insure_incorporation:
    'Si votre entreprise est une incorporation, désirez-vous assurer celle-ci ?',
  goods_location: 'Adresse où sont situés les biens professionnels',
  goods_has_creditors: 'Y a-t-il un ou des créanciers sur les biens assurés ?',
  creditors_name: 'Veuillez indiquer le nom du/des créanciers',
  creditors_address: "Veuillez indiquer l'adresse du/des créanciers",
  goods_description: 'Description des biens',
  location_at_risk:
    'Est-ce que votre emplacement d’affaires est exposé à un risque avoisinant suivant : bar, salle de billard, arcade, prêteur sur gage, maison de chambre, discothèque ou locaux vacants ?',
  name_activities_others: 'Nom et activités des autres occupants du bâtiment',
  wants_protection: 'Souhaitez-vous obtenir cette protection ?',
  past_claims:
    'Avez-vous eu des réclamations au cours des 5 dernières années pour l’ensemble des protections proposées ?',
  past_claims_specify: 'Veuillez préciser :',
  terminated:
    'Est-ce qu’un assureur a déjà résilié  votre assurance ou refusé de la renouveler ?',
  terminated_specify: 'Veuillez préciser :',
  disciplined:
    'Avez-vous déjà fait l’objet de sanctions disciplinaires de la part de votre association ou d’un autre organisme professionnel ?',
  disciplined_specify: 'Veuillez préciser :',
}