const formatSubmissionToFormData = data =>
  Object.fromEntries(
    Object.entries(data)
      .filter(([key, item]) => {
        if (key === 'choices') return false
        if (item === null) return false
        return true
      })
      .map(([key, item]) => {
        let itemToUse = item

        if (typeof item === 'object' && !Array.isArray(item)) {
          return [key, formatSubmissionToFormData(item)]
        }

        if (item === true) itemToUse = 'yes'
        if (item === false) itemToUse = 'no'

        return [
          key,
          {
            value: itemToUse,
          },
        ]
      }),
  )

export default formatSubmissionToFormData