export default {
  firstname: 'First Name',
  lastname: 'Last Name',
  member_number: 'Membership Number',
  company_name: 'Name of your company',
  email: 'Email Addresss',
  activities: 'Activities',
  address: {
    street: 'Address',
    city: 'City',
    postal_code: 'Postal Address',
  },
  phone: {
    main: 'Phone Number',
    secondary: 'Alternate Phone Number',
    fax: 'Fax Machine',
  },
  criminal_records: 'Do you have a criminal record?',
  criminal_records_detail: 'If yes, please explain :',
  specialities: 'Specialities:',
  speciality_details: 'Other specialities:',
  recognized_activities_techniques:
    'What are the activities and/or techniques recognized by your association?',
  unrecognized_activities:
    'Are there activities or techniques not recognized by your association?',
  unrecognized_activities_details: 'If yes, please explain :',
  ambassador_member: 'Are you an ambassador member?',
  services_outside_canada: 'Do you provide services outside of Canada?',
  annual_income:
    'What is your annual income related to your professional practice?',
  leased_local: 'Do you carry out your activities in a leased premises?',
  local_shared:
    "Are there other professionals sharing your premises?",
  insure_incorporation:
    'If your business is an incorporation, do you want to insure it?',
  goods_location: 'Address where the business assets are located',
  goods_has_creditors: 'Are there one or more creditors on the insured property?',
  creditors_name: 'Please indicate the name(s) of the creditors',
  creditors_address: "Please indicate the addresses of the creditors",
  goods_description: 'Description of the goods',
  location_at_risk:
    'Is your business location exposed to one or more of the following neighboring risks: bar, pool hall, arcade, pawnshop, rooming house, nightclub or vacant premises?',
  name_activities_others: 'Name and activities of the other occupants of the building',
  wants_protection: 'Would you like to obtain this protection?',
  past_claims:
    'Have you had any claims in the last 5 years under any of the proposed coverage?',
  past_claims_specify: 'If yes, please explain:',
  terminated:
    'Has an insurer ever canceled or refused to renew any of your insurance policies?',
  terminated_specify: 'If yes, please explain:',
  disciplined:
    'Have you ever been disciplined by your association or another professional organization?',
  disciplined_specify: 'If yes, please explain:',
}