import React, { useEffect } from 'react';
import { useRoute } from 'wouter';
import useWithClient from '../helpers/useWithClient';
import useWithGroup from '../helpers/useWithGroup';
import { useStateValue } from '../states';
import StepWrapper from './StepWrapper';
import {FormattedHTMLMessage} from "react-intl";

const IdentityVerifiedWrapper = () => {
  const [, dispatch] = useStateValue()
  const [, { client: clientId, group: groupId }] = useRoute('/form/continue/client/:client/group/:group');
  const signature = new URL(window.location.href).searchParams.get('signature') || false;
  const [client, clientLoading] = useWithClient(clientId, groupId, signature, {
    scopes: ['has_active_insurance']
  }, false)
  const [group, groupLoading] = useWithGroup(groupId)
  const somethingLoading = clientLoading || groupLoading

  useEffect(() => {
    if (group && client && !client.has_active_insurance) {
      const keysMapping = {
        first_name: 'firstname',
        last_name: 'lastname',
        email: 'email',
      }

      const dataMapper = data => Object.fromEntries(
        Object.entries(data)
          .filter(([key, item]) => item !== null && key in keysMapping)
          .map(([key, item]) => {
            return [
              keysMapping[key],
              {
                value: item,
              },
            ]
          }),
      )

      dispatch({
        type: 'setLocale',
        locale: client.lang,
      })

      dispatch({
        type: 'setClient',
        client: client,
      })

      dispatch({
        type: 'setFormData',
        data: dataMapper(client),
      })

      dispatch({
        type: 'setStep',
        step: 1,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, client])

  if (somethingLoading) {
    return <FormattedHTMLMessage id="general.loading" />
  }

  return <StepWrapper />
}

export default IdentityVerifiedWrapper
