export default {
  limit: 'Limit: $10000 per claim (additional premium of 32.70$)',
  legal: `
    <section class="contract">
      <h4>ABOUT THE COVERAGE</h4>
      <p>
        This coverage applies to loss to the insured due to misappropriation of property, including money and securities, as a direct result of any dishonest act by its employees or their accomplices, per loss up to the stipulated amount.
      </p>
      <p>
        This coverage applies the loss or deterioration of money and securities due to fraudulent removal, destruction or disappearance from the insured premises banking location or other similar places of deposit.
      </p>
      <p>
        This coverage applies the loss or deterioration of money and securities occurring outside of the insured premises due to removal, destruction or disappearance during transport by a carrier or a transport company using armoured cars. 
      </p>
      <p>
        This coverage apples to loss relating to counterfeit money orders or currency resulting from the acceptance in good faith, against value, of counterfeit Canadian or American banknotes.
      </p>
      <p>
       This coverage applies to a counterfeit-related loss by the assured as a depositor incurred by the insured as a result of any promise of payment purported to be made by the insured through counterfeit cheques or drafts in the name of the insured.
      </p>
    </section>
  `,
}
