export default {
    success: `
        <h2>We received your request</h2>
        <p class="lead">
            Your request to disable your next renewal has been received. An agent may be in contact with you shortly.
        </p>`,
    fail: `
        <h2>Your request could not be processed</h2>
        <p class="lead">
            Please contact our team; an error occurred during disabling of your renewal.
        </p>
        <hr class="my-4"/>
        <p>Essor Insurance, taking care of clients for more than sixty years.</p>`,
}