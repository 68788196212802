import React from 'react'
import BasePage from './BasePage'
import HelpCard from './Sections/HelpCard'

import MassageCouImage from '.././assets/img_massagecou.jpg'
import MassageImage from '.././assets/img_massage.jpg'
import LogoANPQ from '.././assets/logo-ANPQ.gif'
import LogoANQ from '.././assets/logo-ANQ.gif'
import LogoRITMA from '.././assets/logo-RITMA.png'
import LogoRMPQ from '.././assets/logo-RMPQ.gif'
import LogoRMQ from '.././assets/logo-RMQ.gif'
import LogoACAM from '.././assets/logo-ACAM.gif'
import LogoANN from '.././assets/logo-ANN.gif'
import LogoAQO from '.././assets/logo-AQO.gif'
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";

const PageWelcome = () => (
  <BasePage className="d-flex flex-column justify-content-center flex-fill mx-auto my-4">
    <section>
      <div className="container padding-md">
        <div className="row justify-content-center">
          <div className="col-lg-10 text-center">
              <FormattedHTMLMessage id="pageWelcome.welcome"/>
          </div>
        </div>
      </div>

      <div
        className="cover"
        style={{ backgroundImage: `url('${MassageCouImage}')` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ">
              <FormattedHTMLMessage id="pageWelcome.slogan"/>
            </div>
          </div>
        </div>
      </div>

      <div className="container padding-lg">
        <div className="row">
          <div className="col">
            <FormattedHTMLMessage id="pageWelcome.introduction"/>
          </div>
        </div>
      </div>

      <div
        className="cover"
        style={{ backgroundImage: `url('${MassageImage}')` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-center text-lg-left">
              <FormattedHTMLMessage id="pageWelcome.hook_text"/>
            </div>
          </div>
        </div>
      </div>

      <div className="container padding-lg">
        <div className="row spacer-bottom-lg">
          <div className="col">
            <FormattedHTMLMessage id="pageWelcome.description"/>
          </div>
        </div>

        <div className="row justify-content-center align-items-center spacer-bottom-lg">
          <div className="col-lg-12 spacer-bottom-sm">
            <p className="lead">
              <FormattedMessage id="pageWelcome.please_select"/>
            </p>
          </div>

          <div className="col-md col-6">
            <a
              href="/form/anpq"
              target="_blank"
            >
              <img src={LogoANPQ} alt="ANPQ" />
            </a>
          </div>

          <div className="col-md col-6">
            <a
              href="/form/anq"
              target="_blank"
            >
              <img src={LogoANQ} alt="ANQ" />
            </a>
          </div>

          <div className="col-md col-6">
            <a
              href="/form/ritma"
              target="_blank"
            >
              <img src={LogoRITMA} alt="RITMA" />
            </a>
          </div>

          <div className="col-md col-6">
            <a
              href="/form/rmpq"
              target="_blank"
            >
              <img src={LogoRMPQ} alt="RMPQ" />
            </a>
          </div>

          <div className="col-md col-6">
            <a
              href="/form/rmq"
              target="_blank"
            >
              <img src={LogoRMQ} alt="RMQ" />
            </a>
          </div>

          <div className="col-md col-6">
            <a
              href="/form/acam"
              target="_blank"
            >
              <img src={LogoACAM} alt="ACAM" />
            </a>
          </div>

          <div className="col-md col-6">
            <a
              href="/form/aqo"
              target="_blank"
            >
              <img src={LogoAQO} alt="AQO" />
            </a>
          </div>

          <div className="col-md col-6">
            <a
              href="/form/ann"
              target="_blank"
            >
              <img src={LogoANN} alt="ANN" />
            </a>
          </div>
        </div>
      </div>
    </section>
    <HelpCard />
  </BasePage>
)

export default PageWelcome