import React from 'react';
import { useRoute } from 'wouter';
import useWithGroup from '../helpers/useWithGroup';
import StepWrapper from './StepWrapper';

const FormWrapper = () => {
  const [, { group: forGroup }] = useRoute("/form/:group");
  // eslint-disable-next-line no-empty-pattern
  const [] = useWithGroup(forGroup)
  return <StepWrapper />
}

export default FormWrapper
