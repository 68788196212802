import React from 'react'
import { useStateValue } from '../states'

export const GroupLogo = (props) => {
  const [state] = useStateValue()
  const isHome = props.isHome;

  if (!state.group || isHome) {
    return ''
  }

  const logoFilename = `${state.group.title.toLowerCase()}.gif`
  const src = `${state.settings.assetsBaseUrl}/logos/${logoFilename}`
  const alt = `Logo ${state.group.title}`

  return (
    <img
      src={src}
      alt={alt}
      style={{
        width: '10rem'
      }}
    />
  )
}
