export default {
    welcome: `
      <h1>
        Welcome to the professional liability insurance site for Alternative Medicine Associations.
      </h1>`,
    slogan: `<h2>An insurance program specifically designed for you.</h2>`,
    introduction: `
        <p>Hello.</p>
        <p>You are at the new professional liability insurance site., intended specially for Alternative Medicine Associations. The goal of this transactional site is to improve and accelerate ESSOR Insurance’s service by allowing you to enrol directly and to offer you a product adapted to your profession.</p>
        <p>Please feel free to share your comments with us.</p>`,
    hook_text:`<h2>A comprehensive single insurance plan</h2>`,
    description: `
       <p>
          ESSOR Insurance offers you a comprehensive single insurance plan, specifically adapted to members of Alternative Medicine Associations.
        </p>
        <p>
          Concerned about always offering you more, we offer more extended coverage including general liability and professional liability.
        </p>
        <p>
          This insurance also gives you protection for your company’s assets as well as theft and fraud coverage. 
        </p>`,
    please_select: `Please select your association in order to subscribe to your professional insurance online.`
}
