import classnames from 'classnames'
import _get from 'lodash.get'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import * as yup from 'yup'
import { useStateValue } from '../states'

const fieldTypes = ['text', 'email', 'radio', 'checkbox', 'select', 'textarea', 'number']
const validationTypes = ['string', 'number', 'date']

const Input = originalProps => {
  // Global App States
  const [globalState, globalDispatch] = useStateValue()

  const props = {
    ...originalProps,
    fieldType: originalProps.fieldType || 'text',
    validationType: originalProps.validationType || 'string',
    fieldRules: null,
  }

  const {
    fieldType,
    markAsDirty,
    validationType,
    required,
    rules,
    applyFilterOnChange,
    onChange,
    onClick,
    onBlur,
    onRender,
    choices,
    rows,
    validationGroup,
    rawPropsToInput,
    fieldPath,
    dependOn,
  } = props

  let { defaultValue, valueOnMount, isDirty, langPath, label  } = props
  let fieldRules

  // Set default lang path if none set
  langPath = langPath || `form.${fieldPath}`
  label = label || null;

  // Default value
  defaultValue = fieldType === 'checkbox' ? [] : ''

  // Value on mount
  valueOnMount = _get(globalState, `formData.${fieldPath}.value`, defaultValue)

  // isDirty
  isDirty = typeof markAsDirty !== 'undefined' ? markAsDirty : !!valueOnMount

  // Input state
  const [state, dispatch] = useState({
    isDirty,
    hasError: false,
    value: valueOnMount
  })
  
  if (validationType && validationTypes.includes(validationType)) {
    fieldRules = yup[props.validationType]()
  }

  if (fieldRules && required) {
    fieldRules = fieldRules.required()
  }

  if (fieldRules && rules) {
    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const [, rule] of Object.entries(rules)) {
      fieldRules = fieldRules[rule.rule](...(rule.args || []))
    }
  }

  const yupSchema = yup.object().shape({
    [fieldPath]: fieldRules,
  })

  /**
   * Save input's validation rules to global state
   */
  const saveSchemaToGlobalState = () => {
    if (validationGroup) {
      globalDispatch({
        type: 'updateFormData',
        targets: {
          [`${fieldPath}.validationGroup`]: validationGroup,
        },
      })
    }

    const targets = {
      [`${fieldPath}.fieldRules`]: fieldRules
    }

    if (dependOn.lenght !== 0) {
      targets[`${fieldPath}.dependOn`] =  dependOn;
    }

    globalDispatch({
      type: 'updateFormData',
      targets: targets
    })
  }

  /**
   * Save input's value to global state
   */
  const saveValueToGlobalState = () =>
    globalDispatch({
      type: 'updateFormData',
      targets: {
        [`${fieldPath}.value`]: state.value,
      },
    })

  /**
   * Save input's data to global state
   */
  const saveToGlobalState = () => {
    saveValueToGlobalState()
    saveSchemaToGlobalState()
  }

  /**
   * Validate value & set validation state
   */
  const validate = value => {
    const result = (() => {
      try {
        yupSchema.validateSync({
          [fieldPath]: typeof value !== 'undefined' ? value : state.value,
        })

        return true
      } catch (error) {
        return error
      }
    })()
    dispatch({
      ...state,
      hasError: typeof result === 'object' ? result : false,
    })
  }

  /**
   * Save input's value in local state & set input as dirty as we changed
   * its value
   * @param {KeyboardEvent} e
   */
  const handleOnChange = e => {
    let {
      target: { value },
    } = e

    /**
     * Allow parent to do transform value on every change
     */
    if (applyFilterOnChange && typeof applyFilterOnChange === 'function') {
      value = applyFilterOnChange(value)
    }

    if (fieldType === 'checkbox') {
      let oldValue = state.value
      if (oldValue.includes(value)) {
        oldValue = oldValue.filter(item => item !== value)
      } else {
        oldValue = oldValue.concat([value])
      }
      value = oldValue
    }

    const previousState = state
    const nextState = {
      ...state,
      isDirty: true,
      value,
    }

    /**
     * Allow parent to do something on every change
     */
    if (onChange && typeof onChange === 'function') {
      const changeHandler = onChange({
        ...props,
        previousState,
        nextState,
      })

      // Prevent validation & state update
      if (changeHandler === false) {
        return
      }
    }

    validate(value)
    dispatch(nextState)
  }

  /**
   * Handle onBlue event
   * @param {KeyboardEvent} e
   */
  const handleOnBlur = e => {
    /**
     * Allow parent to do something on every change
     */
    if (onBlur && typeof onBlur === 'function') {
      const blurHandler = onBlur(e)

      // Prevent validation & state update
      if (blurHandler === false) {
        return
      }
    }

    saveToGlobalState()
  }

  /**
   * Handle onClick event
   * @param {KeyboardEvent} e
   */
  const handleOnClick = e => {
    /**
     * Allow parent to do something on every change
     */
    if (onClick && typeof onClick === 'function') {
      onClick(e)
    }
  }

  /**
   * Allow parent to do something on every render
   */
  if (onRender && typeof onRender === 'function') {
    onRender(props)
  }

  /**
   * On:
   * - mount
   * - input's value change
   * - dirty state change
   * Do:
   * - Validate
   * - Save schema to global state
   */
  useEffect(() => {
    saveToGlobalState()
    validate()
    // eslint-disable-next-line
  }, [state.value, state.isDirty])

  const renderFormattedMessage = (() => {
    if (label != null) return label[globalState.locale];
    if (!langPath) return '';
    return <FormattedMessage id={langPath} />
  })()

  const renderValidationError = (() => {
    if (!state.hasError || !state.hasError.errors) return ''
    return <div className="invalid-feedback">{state.hasError.errors[0]}</div>
  })()

  const renderInputText = () => (
    <input
      data-hj-allow
      {...rawPropsToInput}
      type={fieldType}
      id={fieldPath}
      onChange={handleOnChange}
      onClick={handleOnClick}
      value={state.value}
      onBlur={handleOnBlur}
      className={classnames('form-control', {
        'is-invalid': state.isDirty && state.hasError,
      })}
    />
  )

  const getDataFromChoice = ({ value, label }) => ({
    id: `${langPath}-${value}`,
    value,
    originalLabel: label,
    label:
      typeof label === 'object' && 'fr' in label && 'en' in label ? (
        label[globalState.locale]
      ) : (
        <FormattedMessage id={label} />
      ),
  })

  const renderInputRadio = () => {
    if (!choices) return []
    const components = []

    choices.forEach(choice => {
      const { id, label, value } = getDataFromChoice(choice)
      components.push(
        <div className="form-check" key={id}>
          <input
            data-hj-allow
            {...rawPropsToInput}
            className="form-check-input"
            type="radio"
            name={fieldPath}
            id={id}
            value={value}
            onChange={handleOnChange}
            checked={state.value === value}
          />
          <label className="form-check-label" htmlFor={id}>
            {label}
          </label>
        </div>,
      )
    })

    return components
  }

  const renderSelect = () => {
    if (!choices) return []
    const components = []

    choices.forEach(choice => {
      const { id, value, originalLabel, label } = getDataFromChoice(choice)
      components.push(
        typeof label !== 'string' ? (
          <FormattedMessage id={originalLabel} key={id}>
            {message => <option value={value}>{message}</option>}
          </FormattedMessage>
        ) : (
          <option key={id} value={value}>
            {label}
          </option>
        ),
      )
    })

    return (
      <select {...rawPropsToInput} onChange={handleOnChange} name={fieldPath}>
        {components}
      </select>
    )
  }

  const renderInputCheckbox = () => {
    if (!choices) return []
    const components = []
 
    choices.forEach(choice => {
      const { id, label, value } = getDataFromChoice(choice)
      components.push(
        <div className="form-check" key={id}>
          <input
            data-hj-allow
            {...rawPropsToInput}
            className="form-check-input"
            type="checkbox"
            name={fieldPath}
            id={id}
            value={value}
            onClick={handleOnChange}
            defaultChecked={state.value.includes(value)}
          />
          <label className="form-check-label" htmlFor={id}>
            {label}
          </label>
        </div>,
      )
    })

    return components
  }

  const renderTextarea = () => (
    <textarea
      data-hj-allow
      {...rawPropsToInput}
      id={fieldPath}
      onChange={handleOnChange}
      onClick={handleOnClick}
      onBlur={handleOnBlur}
      className={classnames('form-control', {
        'is-invalid': state.isDirty && state.hasError,
      })}
      defaultValue={state.value}
      rows={rows || 3}
    />
  )

  const renderInput = (() => {
    if (!props.fieldType) return ''

    switch (props.fieldType) {
      case 'text':
      case 'email':
      case 'number':
        return renderInputText()
      case 'radio':
        return renderInputRadio()
      case 'checkbox':
        return renderInputCheckbox()
      case 'select':
        return renderSelect()
      case 'textarea':
        return renderTextarea()
      default:
        return ''
    }
  })()

  // Warning from Mathias 
  // Careful using state.hasError won't have the effect you want if an input is optional with a validation.
  return (
    <div
      className={classnames(
        'c-input',
        'form-group',
        `id-${fieldPath}`,
        `type-${fieldType}`,
        {
          invalid: state.isDirty && state.hasError,
          valid: state.isDirty && !state.hasError,
        },
      )}
    >
      <label>
        <span>
          {renderFormattedMessage} 
          {
            (state.hasError) 
              ? <span style={{display: 'inline'}} className="label-required"> *</span>
              : ''
          }
        </span>
      </label>
      {renderInput}
      {renderValidationError}
    </div>
  )
}

Input.propTypes = {
  fieldPath: PropTypes.string.isRequired,
  validationGroup: PropTypes.string,
  langPath: PropTypes.string,
  label: PropTypes.object, // { "fr": "Label", "en": "Label" }
  dependOn: PropTypes.string,
  fieldType: PropTypes.oneOf(fieldTypes),
  validationType: PropTypes.oneOf(validationTypes),
  markAsDirty: PropTypes.bool,
  required: PropTypes.bool,
  rules: PropTypes.arrayOf(PropTypes.object),
  onRender: PropTypes.func,
  choices: PropTypes.arrayOf(PropTypes.shape()), // [ {"label": "i18n.label", "value": 10}, {"label": { "fr": "Label", "en": "Label" }, "value": 10} ]
  applyFilterOnChange: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  rawPropsToInput: PropTypes.shape(),
  rows: PropTypes.number,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.array,
  ]),
  valueOnMount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.array,
  ]),
  isDirty: PropTypes.bool,
}

Input.defaultProps = {
  fieldType: 'text',
  langPath: '',
  validationGroup: '',
  validationType: 'string',
  markAsDirty: false,
  required: false,
  rules: [],
  onRender: null,
  applyFilterOnChange: null,
  onChange: null,
  onClick: null,
  onBlur: null,
  rawPropsToInput: null,
  choices: [],
  rows: 4,
  defaultValue: '',
  valueOnMount: '',
  isDirty: false,
  dependOn: '',
  label: null
}

export default Input
