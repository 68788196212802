import flatten from 'flat'

import autoRenewDisabled from "./autoRenewDisabled";
import cart from './cart'
import certificateModification from "./certificateModification";
import contract from './contract'
import crimeInsurance from './crimeInsurance'
import form from './form'
import general from './general'
import pageWelcome from './pageWelcome'
import renewal from './renewal'
import step8 from './step8'
import steps from './steps'
import submission from './submission'
import transaction from './transaction'
import yup from "./yup";


export default flatten({
  autoRenewDisabled,
  cart,
  certificateModification,
  contract,
  crimeInsurance,
  form,
  general,
  pageWelcome,
  renewal,
  step8,
  steps,
  submission,
  transaction,
  yup,
})
