export default {
  intro:
    'Veuillez lire le contrat ci-dessous et signer sous la case prévue à cette effet pour accepter et continuer.',
  sign_below: "Veuillez signer dans l'espace ci-dessous:",
  sign_confirm: "Veuillez confirmer votre signature.",
  redo: "Recommencer",
  confirm: "Confirmer",
  contract: `
    <div class="contract">
      <h4>CONTRAT COURTIER CLIENT</h4>
      <p>
        Sujet aux conditions qui suivent, je vous confie le mandat d’agir comme
        mon courtier et requiers vos services comme conseiller professionnel en
        matière d’assurance.
      </p>

      <ol>
        <li>
          <strong>MISSION :</strong> Vous devez, d’une part, identifier et
          évaluer mes besoins en assurance selon les informations que je vous
          communique et, d’autre part, souscrire l’assurance conformément à mes
          instructions portant sur le montant d’assurance et la nature des
          garanties.
        </li>

        <li>
          <strong>RENOUVELLEMENT :</strong> Vous devez, sans autre instruction
          additionnelle, renouveler les assurances souscrites par votre
          entremise lorsqu’elles échoient.
        </li>

        <li>
          <strong>AGGRAVATION DES RISQUES ASSURÉS :</strong> Pendant que
          l’assurance est en vigueur et préalablement à tout renouvellement de
          police, je dois vous informer de tout changement dans la nature et
          l’emploi des biens assurés ou de toute modification de mes activités
          comme assuré de nature à influencer de façon importante un assureur
          dans l’établissement de la prime, l’appréciation du risque ou la
          décision de l’accepter.
        </li>

        <li>
          <strong>CONDITIONS FINANCIÈRES</strong>
          <ol>
            <li>
              <strong>PAIEMENT DES PRIMES :</strong> Les primes exigibles pour
              chaque police, avenant ou renouvellement, incluant les taxes sur
              celles-ci de même que les honoraires, le cas échéant, sont
              payables sur demande.
            </li>

            <li>
              <strong>DÉFAUT DE PAIEMENT :</strong> Si je fais défaut de payer à
              échéance toute somme due pour le paiement des primes, le
              remboursement des avances et/ou la rémunération et autres frais,
              vous êtes alors autorisé à aviser l’assureur que la police,
              l’avenant ou le certificat de renouvellement est non requis ou,
              lorsque l’assurance est entrée en vigueur, à demander en mon nom
              la résiliation de la police, avenant ou renouvellement; la demande
              de résiliation faite en mon nom pourra porter sur une ou plusieurs
              polices dans la mesure où cela est requis pour que le
              remboursement de primes non acquises défraie la somme totale
              pouvant alors vous être due; un avis préalable d’au moins sept (7)
              jours devra m’être adressé avant que vous demandiez à l’assureur
              la résiliation de la police ou avant que vous ne l’avisiez que la
              police, l’avenant ou le certificat de renouvellement n’est pas
              requis. Si un créancier est nommé bénéficiaire de la police, tel
              avis lui sera expédié dans le délai prévu par la loi ou par la
              police.
            </li>

            <li>
              <strong>EXPÉDITION DES AVIS :</strong> L’avis préalable requis en
              vertu de la clause 4.2 doit être par écrit et est présumé avoir
              été suffisamment et valablement donné s’il est livré de main à
              main. Cet avis peut être également expédié par la poste ou par
              service de courrier et tel avis est réputé reçu le septième (7e)
              jour ouvrable suivant la mise à la poste ou sa remise au service
              de courrier. L’avis peut être donné par télécopieur et, en ce cas,
              il est réputé reçu à la date indiquée au relevé de confirmation de
              réception par télécopieur. L’avis peut être donné par courriel.
            </li>
          </ol>
        </li>

        <li>
          <strong>FIN DU CONTRAT :</strong> Vous pouvez mettre fin au présent
          contrat en m’adressant un avis écrit comportant renonciation au
          mandat, auquel cas le contrat prend fin à la date de réception de tel
          avis. Pour ma part, je peux également mettre fin au contrat en vous
          adressant un avis écrit de révocation du mandat par la poste ou par
          service de courrier, auquel cas le contrat prend fin au moment de
          votre réception de l’avis.
        </li>
      </ol>
    </div>
  `,
}
