/* eslint-disable no-template-curly-in-string */

export const mixed = {
  default: 'Ce champ est invalide',
  required: 'Ce champ est requis',
  oneOf: "Ce champ doit contenir l'une des valeurs suivantes: ${values}",
  notOneOf: "Ce champ ne doit pas contenir l'une des valeurs suivantes: ${values}",
  notType: ({ type }) => {
    if (type === 'number') {
      return 'Ce champ doit être un nombre'
    }

    return `${`Ce champ doit être de type \`${type}\``}`
  },
  defined: 'Ce champ doit être défini',
}

export const string = {
  length: 'Ce champ doit avoir exactement ${length} caractères',
  min: 'Ce champ doit avoir au moins ${min} caractères',
  max: 'Ce champ doit avoir au plus ${max} caractères',
  matches: 'Ce champ doit correspondre à ce qui suit: "${regex}"',
  email: 'Ce champ doit être un courriel valide',
  url: 'Ce champ doit être une URL valide',
  trim: "Ce champ ne doit pas contenir d'espace avant et après le texte",
  lowercase: 'Ce champ doit contenir des minuscules seulement',
  uppercase: 'Ce champ doit contenir des majuscules seulement',
  postalCode: 'Ce champ doit être un code postal valide',
}

export const number = {
  min: 'La valeur doit être plus grande ou égale à ${min}',
  max: 'La valeur doit être plus petite ou égale à ${max}',
  lessThan: 'La valeur doit être inférieure à ${less}',
  moreThan: 'La valeur doit être supérieure à ${more}',
  notEqual: 'La valeur ne doit pas être égale à ${notEqual}',
  positive: 'Ce champ doit contenir une valeur positive',
  negative: 'Ce champ doit contenir une valeur négative',
  integer: 'La valeur doit être un nombre entier',
}

export const date = {
  min: 'Ce champ doit être au plus tard ${min}',
  max: 'Ce champ doit être au plus tôt ${max}',
}

export const boolean = {}

export const object = {
  noUnknown: 'Ce champ ne peut pas avoir des clés non spécifiées',
}

export const array = {
  min: 'Ce champ doit avoir au moins ${min} items',
  max: 'Ce champ doit être inférieur ou égal ${max} items',
}

export default {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
}
