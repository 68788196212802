export default {
  company_name: 'Essor Assurances',
  slogan: 'ESSOR Assurances, on s’en occupe depuis plus de 60 ans.',
  welcome: 'Bienvenue !',
  select_a_group: 'Veuillez sélectionner un groupe pour continuer',
  yes: 'Oui',
  no: 'Non',
  other: 'Autre',
  others: 'Autres',
  go_to_step: "Aller à l'étape",
  previous_step: "Aller à l'étape précédente",
  next_step: "Aller à l'étape suivante",
  begin: 'Débuter',
  please_wait: 'Veuillez patienter...',
  loading: `<section>Chargement en cours, veuillez patienter...</section>`,
  support: `
      <p>
        Pour assistance, veuillez communiquer avec un membre de notre équipe par courriel <a href="mailto:medecinealternative@essor.ca">medecinealternative@essor.ca</a> ou composez le 1-877 883-7767 poste 81040, merci.
      </p>`,
  notFound:`
      <h2>Oops!</h2>
      <p class="lead">La page que vous avez demandé n'existe pas.</p>`,
  helpCard:`
      <h3>Vous avez d’autres besoins en assurance ?</h3>
      <p>Nos courtiers en assurance de dommages pourront vous proposer une gamme complète de produits.</p>
      <p><strong>Automobile – Véhicule récréatif – Groupe – Habitation – Entreprise</strong></p>
      <p>Visitez <a href="https://www.essor.ca/fr"><strong>essor.ca</strong></a> pour en savoir plus.</p>`,
  excluded_activities:`
      <p><em>Veuillez noter que ces activités sont exclues en tout temps : Antennes de Lecher, Ayur-Véda, 
      Biologie totale, Décodage Biologique, Déprogrammation biologique, Désintoxication ( alimentaire), 
      Elixirs floraux Bach, Iridologie, Irrigation du côlon, Kinésiologie appliquée, Kinésiologie holistique , 
      Magnétisme, Médecine Traditionnelle Africaine , Médecine Traditionnelle Chinoise, Nutrithérapie, Phytothérapie, 
      Reconnective Healing, Sympathicothérapie, Technique Énergétique, Thérapie du sport.</em></p>`,
}
