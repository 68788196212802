import React from 'react'
import {useStateValue} from '../states';

const LanguageSwitcher = ({className}) => {

    const [state, dispatch] = useStateValue();

    if (state.locale === 'fr') {
        return (

        <div className={className}>
            <button
            type="button"
            className="btn btn-link"
            onClick={() =>
                dispatch({
                    type: 'setLocale',
                    locale: 'en',
                })
            }
        >
            English
        </button>
        </div>
    );
    }
    else if (state.locale === 'en') {
        return (
        <div className="container text-center p-6">
            <button
                type="button"
                className="btn btn-link"
                onClick={() =>
                    dispatch({
                        type: 'setLocale',
                        locale: 'fr',
                    })
                }
            >
                Français
            </button>
        </div>
    );
    }
}

export default LanguageSwitcher;