import _set from 'lodash.set'

const Reducer = (previousState, action) => {
  switch (action.type) {
    case 'setGroup':
      return {
        ...previousState,
        group: action.group,
      }
    case 'setClient':
      return {
        ...previousState,
        client: action.client,
      }
    case 'setPriceReducer':
      return {
        ...previousState,
        priceReducer: action.priceReducer,
      }
    case 'setFormData':
      return {
        ...previousState,
        formData: action.data,
      }
    case 'setRenewal':
      return {
        ...previousState,
        isRenewal: true,
        formData: {
          ...previousState?.formData || {},
          effective_date: {
            ...previousState?.formData?.effective_date || {},
            value: action.date
          },
        }
      }
    case 'setStep':
      return {
        ...previousState,
        step: action.step,
      }
    case 'setLocale':
      document.cookie = `locale=${action.locale}; expires=${new Date(new Date().getTime()+1000*60*60*24*365).toUTCString()}; path=/`;
      return {
        ...previousState,
        locale: action.locale,
      }
    case 'updateCart':
      return {
        ...previousState,
        cart: action.cart,
      }
    case 'emailExists':
      return {
        ...previousState,
        emailExists: !!action.value
      }
    case 'addChoice':
      return {
        ...previousState,
        formData: {
          ...previousState?.formData || {},
          choices: [
            ...previousState?.formData?.choices || [],
            ...action.choices || []
          ]
        },
      }
    case 'updateFormData':
      Object.entries(action.targets).forEach(([target, value]) => {
          _set(previousState, `formData.${target}`, value)
      })

      return {
        ...previousState,
        formData: {
          ...previousState.formData,
          ...Object.fromEntries(Object.entries(action.targets)),
        },
      }
    default:
      return previousState
  }
}

export default Reducer
