export default {
  your_cart: 'Votre panier',
  select: 'Sélectionner',
  remove: 'Retirer',
  product: 'Produit',
  price: 'Prix',
  type: 'Type',
  tps: 'TPS',
  tvq: 'TVQ',
  sub_total: 'Sous-total',
  total: 'Total',
  text_taxes: 'Le montant de la prime inclut les taxes et les honoraires du cabinet',
  activities: 'Activités'
}
