import React from 'react'
import BasePage from './BasePage'
import HelpCard from './Sections/HelpCard'
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";

const TransactionSuccess = () => (
  <BasePage className="d-flex flex-column justify-content-center text-center flex-fill mw-500 mx-auto my-4">
    <section>
      <div className="jumbotron">
        <FormattedHTMLMessage id="transaction.success" />
        <hr className="my-4" />
        <p>
            <FormattedMessage id="general.slogan" />
        </p>
      </div>
    </section>
    <HelpCard />
  </BasePage>
)

export default TransactionSuccess
