export default {
    success: `
        <h2>Merci pour votre demande</h2>
        <p class="lead">
          Vous recevrez votre certificat d’assurance par courriel dans les
          prochaines minutes.
        </p>`,
    fail: `
        <h2>Un problème est survenu avec votre transaction</h2>
        <p class="lead">
          Un courtier communiquera avec vous pour compléter votre demande.
        </p>`,
}