export default {
  liability_insurance: 'Liability insurance',
  property_insurance: 'Property insurance',
  crime_insurance: 'Insurance against embezzlement',
  accessory_protections: 'Incidental protection',
  public_liability: 'Limit:',
  annual_premium: 'Insurance premium',
  insurance_amount: "Insurance amount",
  type: "Type",
  configure: 'Configure',
  you_refused: 'You have refused "{title}" protection',
  i_changed_my_mind: "I changed my mind",
  effective_date: "Effective date",
  make_payment: 'Proceed to payment',
  send_request: 'Send request',
  representative_will_reach_u: 'A broker will contact you to complete your request',
  liability_insurance_text: `
      <p>
        Professional and general liability insurance and reimbursement of legal costs relating to criminal charges 
      </p>
      <p>
        Please choose the desired liability insurance according to your specialties:
      </p>`,
  accessory_protections_text: `
      <p>
        We have advantageous pricing to provide several other additional (or: optional) activities and services 
        offered by members of the association. Contact us for more information.
      </p>`,
}
