const formatChoices = choices => {
  return choices.map(
    // eslint-disable-next-line camelcase
    ({ choice_id }) => choice_id,
  )
}

const formatFormData = data =>
  Object.fromEntries(
    Object.entries(data).map(([key, item]) => {
      if (!item) return []

      if (key.includes('.fieldRules') || key.includes('.validationGroup')) {
        return []
      }

      if (
        typeof item === 'object' &&
        !Array.isArray(item) &&
        !('value' in item)
      ) {
        return [key, formatFormData(item)]
      }

      let { value } = item
      if (value === 'yes') value = true
      if (value === 'no') value = false
      if (key === 'choices' && Array.isArray(item))
        value = formatChoices(item)

      return [key, value]
    }),
  )

export default formatFormData