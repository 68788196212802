import React from 'react'
import { useStateValue } from '../states'

const DebugMenu = () => {
  const [state, dispatch] = useStateValue()

  if (state.settings.env !== 'dev') {
    return ''
  }

  return (
    <div className="debug-menu">
      <div className="container">
        <div className="text-center m-0">
          <h6><span className="badge badge-warning">This toolbar is visible only in DEV environment</span></h6>
        </div>
        <ul>
          <li>
            <table className="w-100">
              <tbody>
                <tr>
                  <td>API</td>
                  <td>{state.settings.apiBaseUrl}</td>
                </tr>
                <tr>
                  <td>Moneris</td>
                  <td>{state.settings.monerisTransactionUrl}</td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <table className="w-100">
              <tbody>
                <tr>
                  <td>Locale</td>
                  <td>{state.locale}</td>
                </tr>
                <tr>
                  <td>Switch to</td>
                  <td>
                    <button
                      type="button"
                      onClick={() =>
                        dispatch({
                          type: 'setLocale',
                          locale: 'fr',
                        })
                      }
                    >
                      fr
                      </button>{' '}
                      /{' '}
                    <button
                      type="button"
                      onClick={() =>
                        dispatch({
                          type: 'setLocale',
                          locale: 'en',
                        })
                      }
                    >
                      en
                      </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <table className="w-100">
              <tbody>
                <tr>
                  <td>Group</td>
                  <td>{state.group && state.group.title ? state.group.title : 'None'}</td>
                </tr>
                <tr>
                  <td>Env</td>
                  <td>{localStorage.getItem('env')}</td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default DebugMenu