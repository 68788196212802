import _get from 'lodash.get'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useStateValue } from '../states'

const useValidateStep = step => {
  const [state] = useStateValue()
  const [isValid, setValid] = useState(false)
  const [langLoaded, setLangLoaded] = useState('')

  useEffect(() => {
    const filterValidationGroup = (group, data, items = []) => {
      Object.entries(data).forEach(([key, item]) => {
        if (
          !item ||
          typeof item !== 'object' ||
          Array.isArray(item) ||
          key.startsWith('_') || // Yup
          ['fieldRules'].includes(key) // Yup
        ) {
          return
        }

        if (!('validationGroup' in item)) {
          filterValidationGroup(group, item, items)
          return
        }

        if (item.validationGroup !== `step${step}`) {
          return
        }

        // add validation for depending on something
        if (item.dependOn.length !== 0) {

          // group activities dependOn logic
          if (item.dependOn.startsWith('group.activities')) {
            const parts = item.dependOn.split('.');
            if (!state.formData.activities.value.includes(parts[parts.length-1]))
              return;
          }

          // add your others dependOn logic here
        }


        items.push([key, item])
      })

      return items
    }

    const fieldsToValidate = filterValidationGroup(
      `step${step}`,
      state.formData,
    )

    if (fieldsToValidate) {
      const [validationObject, validationData] = (() => {
        const object = {}
        const data = {}

        fieldsToValidate.forEach(field => {
          const [key, value] = field
          object[key] = _get(value, 'fieldRules')
          data[key] = _get(value, 'value')
        })

        return [yup.object().shape(object), data]
      })()
      
      ;(async () => {
        try {
          await validationObject.validate(validationData)
          setValid(true)
        } catch (error) {
          setValid(false)
        }
      })()

      /**
       * Load French Yup Validations Feedbacks
       */
      if (langLoaded !== state.locale) {
        ;(async () => {
          try {
            yup.setLocale((await import(`../i18n/${state.locale}/yup`)).default)
          } catch (error) {
            console.error('Error while fetching yup lang for', state.locale)
          }
          setLangLoaded(state.locale)
        })()
      }
    }
  }, [state, step, langLoaded])

  return isValid
}

export default useValidateStep
