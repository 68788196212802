function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getLocale() {
    // Check URL params
    let locale = new URL(window.location.href).searchParams.get('locale');
    if (locale) {
        setCookie("locale", locale, 365);
        return locale;
    } else { // If not set in the URL check the Cookies
        let locale = getCookie("locale");
        if (locale) {
            return locale;
        } else { // If no URL params AND no cookie, return default FR.
            return 'fr';
        }
    }
}

let initialState = {
    locale: getLocale() || 'fr',
    settings: {
        env: 'prod',
        paymentMode: 'prod',
        assetsBaseUrl: 'https://app.medecinealternativeessor.ca/storage',
        apiBaseUrl: 'https://app.medecinealternativeessor.ca/api/v1',
        monerisScriptUrl: 'https://gateway.moneris.com/chktv2/js/chkt_v2.00.js',
        monerisTransactionUrl: 'https://www3.moneris.com/HPPDP/index.php',
    },
    group: null,
    priceReducer: e => e,
    step: 0,
    emailExists: false,
    client: null,
    isRenewal: false,
    formData: {}
}

if (window.location.href.includes('frontend.essor.jonathanlafleur.ca')) {
    initialState = {
        ...initialState,
        settings: {
            ...initialState.settings,
            env: 'dev',
            paymentMode: 'qa',
            assetsBaseUrl: 'http://backend.essor.jonathanlafleur.ca/storage',
            apiBaseUrl: 'http://backend.essor.jonathanlafleur.ca/api/v1',
            monerisScriptUrl: 'https://gatewayt.moneris.com/chktv2/js/chkt_v2.00.js',
            monerisTransactionUrl: 'https://esqa.moneris.com/HPPDP/index.php',
        },
    }
}

else if (window.location.href.includes('medecinealternativeessor.lashopweb.ca')) {
    initialState = {
        ...initialState,
        settings: {
            ...initialState.settings,
            env: 'dev',
            paymentMode: 'qa',
            assetsBaseUrl: 'https://app.medecinealternativeessor.lashopweb.ca/storage',
            apiBaseUrl: 'https://app.medecinealternativeessor.lashopweb.ca/api/v1',
            monerisScriptUrl: 'https://gatewayt.moneris.com/chktv2/js/chkt_v2.00.js',
            monerisTransactionUrl: 'https://esqa.moneris.com/HPPDP/index.php',
        },
    }
}

else if (window.location.href.includes('frontend.essor-form.local')) {
    initialState = {
        ...initialState,
        settings: {
            ...initialState.settings,
            env: 'dev',
            paymentMode: 'qa',
            assetsBaseUrl: 'http://backend.essor-form.local/storage',
            apiBaseUrl: 'http://backend.essor-form.local/api/v1',
            monerisScriptUrl: 'https://gatewayt.moneris.com/chktv2/js/chkt_v2.00.js',
            monerisTransactionUrl: 'https://esqa.moneris.com/HPPDP/index.php',
        },
    }
}

else if (window.location.href.includes('localhost')) {
    initialState = {
        ...initialState,
        settings: {
            ...initialState.settings,
            env: 'dev',
            paymentMode: 'qa',
            assetsBaseUrl: 'http://essor-backend.local/storage',
            apiBaseUrl: 'http://essor-backend.local/api/v1',
            monerisScriptUrl: 'https://gatewayt.moneris.com/chktv2/js/chkt_v2.00.js',
            monerisTransactionUrl: 'https://esqa.moneris.com/HPPDP/index.php',
        },
    }
}

export default Object.freeze(initialState)
