import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useRoute } from 'wouter';
import Contact from '../forms/base/Step1/Contact';
import Input from '../forms/Input';
import formatFormData from '../helpers/formatFormData';
import useValidateStep from '../helpers/useValidateStep';
import useWithClient from '../helpers/useWithClient';
import useWithGroup from '../helpers/useWithGroup';
import ClientCertificateModificationSuccess from '../pages/ClientCertificateModificationSuccess';
import { useStateValue } from '../states';

const Dashboard = () => {
  const [state] = useStateValue();
  const alert = useAlert()
  const valid = useValidateStep('clientAddresses')
  const [succeded, setSucceesed] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [, { client: clientId, group: groupId }] = useRoute("/dashboard/client/:client/group/:group");
  const signature = new URL(window.location.href).searchParams.get('signature') || false;
  const [group, groupLoading, groupHasTriedFetching] = useWithGroup(groupId)
  const [client, clientLoading, clientHasTriedFetching] = useWithClient(clientId, groupId, signature, {
    scopes: ['has_property_insurance', 'signature_update_addresses', 'is_in_renewal_period']
  })
  const somethingIsLoading = groupLoading || clientLoading
  const somethingWentWrong = (groupHasTriedFetching && !group) || (clientHasTriedFetching && !client)

  useEffect(() => {
    document.querySelectorAll('input').forEach((e) => e.disabled = submitting);
  }, [submitting])

  const submit = async () => {
    setSubmitting(true)
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formatFormData(state.formData)),
    }

    try {
      const fetchResponse = await fetch(
        `${state.settings.apiBaseUrl}/client/${clientId}/updateAddresses/${groupId}?signature=${client.signature_update_addresses}`,
        settings,
      )

      if (!fetchResponse.ok) {
        if (typeof fetchResponse.json === 'function') {
          const data = await fetchResponse.json()
          if (typeof data === 'object' && 'errors' in data) {
            throw new Error(data.errors[Object.keys(data.errors)[0]][0])

          }
        } else {
          throw new Error(fetchResponse.statusText)
        }
      }

      setSucceesed(true)
    } catch (e) {
      alert.error(e.message)
    }

    setSubmitting(false)
  }

  if (somethingIsLoading) {
    return <section>Chargement en cours, veuillez patienter...</section>
  }

  if (somethingWentWrong) {
    return <section>Impossible de traiter la demande.</section>
  }

  if (succeded) {
    return <ClientCertificateModificationSuccess />
  }

  const renderRenewalNotice = () => <div>
    <div className="mb-0 bg-warning p-2 pl-3">Renouvellement</div>

    <div className="bg-light p-4 shadow-sm mb-1">
      <p>
        Votre assurance arrive à échéance.
        Veuillez la renouveler dès que possible.
      </p>
      <a href={`/dashboard/client/${clientId}/group/${groupId}/renewal?signature=${client.signature_renewal}&disableRenewSignature=${client.signature_renewal_cancel}`} className="btn btn-primary">Renouveler mon assurance</a>
    </div>
  </div>

  return <section className="dashboard-wrapper">
    <div className="shadow-sm bg-white rounded">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <span className="navbar-brand text-muted">Tableau de bord</span>
        <div className="d-none d-lg-block text-muted">
          <small>{client.first_name} {client.last_name}</small>
        </div>
      </nav>

      {client?.is_in_renewal_period && renderRenewalNotice()}

      <div>
        <div className="mb-0 bg-essor p-2 pl-3 text-light">Mettre vos adresses à jour</div>

        <div className="d-flex flex-wrap flex-lg-nowrap bg-light p-4 shadow-sm mb-1">
          <section className="w-100 flex-fill">
            <h6 className="pb-1 mb-3 border-bottom">Adresse postale</h6>
            <Contact validationGroup="clientAddresses" />
          </section>
          {!client.has_property_insurance ? '' : <section className="w-100 pl-lg-5 flex-fill">
            <h6 className="pb-1 mb-3 border-bottom">Adresse des biens</h6>
            <Input data-hj-allow validationGroup="stepclientAddresses" fieldPath="goods_location" required />
          </section>}

        </div>
      </div>

      <div className="bg-white p-3">
        <button
          type="button"
          className="btn btn-primary"
          disabled={submitting || !valid}
          onClick={submit}>
          {submitting ? 'Veuillez patienter...' : 'Sauvegarder les modifications'}
        </button>
      </div>
    </div>
  </section>
}

export default Dashboard
